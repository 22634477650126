$(function() {
    // Select all links with hashes
    $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function(event) {
        // On-page links
        if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
        && 
        location.hostname == this.hostname
        ) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        // Does a scroll target exist?
        if (target.length) {
            // Only prevent default if animation is actually gonna happen
            event.preventDefault();
            $('html, body').animate({
            scrollTop: target.offset().top - 75
            }, 1000, function() {
            // Callback after animation
            // Must change focus!
            var $target = $(target);
            $target.focus();
            if ($target.is(":focus")) { // Checking if the target was focused
                return false;
            } else {
                $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
            };
            });
        }
        }
    });

    var hamburger = $('.hamburger'),
        nav = $('.rtnu-nav'),
        body = $('body');

    hamburger.on('click', function(){
        $(this).toggleClass('is-active');
        nav.toggleClass('active');
        body.toggleClass('active');
    })

    nav.on("click", "a", function () {
        hamburger.removeClass('is-active');
        nav.removeClass('active');
        body.removeClass('active');
    });


    $(window).on('load scroll unload', function () {
        let scroll = $(window).scrollTop(),
            siteHeader = $('header'),
            topbarHeight = $('.rtnu-top-bar').outerHeight(true);

        if(scroll > 0) {
            siteHeader.addClass('activeScroll');
        } else {
            siteHeader.removeClass('activeScroll');
        }

    });

    // Function to hide nav if clicked anywhere outside of it
    // $(document).mouseup(function(e) {
    //     var container = $('.rtnu-nav');
    //     if (!container.is(e.target) && container.has(e.target).length === 0) {
    //         container.removeClass('active');
    //         $('body').removeClass('active');
    //         $('.hamburger').removeClass('is-active');
    //     }
    // });

    $(document).keyup(function(e) {
        var container = $('.rtnu-nav');
        if (e.key === "Escape") {
            container.removeClass('active');
            $('body').removeClass('active');
            $('.hamburger').removeClass('is-active');
       }
    });
});

// Intersection Observer to add animations
let observedElements = document.querySelectorAll('.inview-element'); // Define the elements you want to intiate an action on

const options = { //define your options
  threshold: 0.5 //50% of the element in view
}

const inViewCallback = entries => {
  entries.forEach(entry => {
    if (entry.isIntersecting) { // define the event/property you want to use
      entry.target.classList.add('inview');
      //do something with the element
    }
  });
}
let observer = new IntersectionObserver(inViewCallback,options); // create a new instance using our callback which contains our elements and actions, using the options we defined

observedElements.forEach(element => {
  
  let dataDelay = element.getAttribute('data-delay');
  
  element.style.transitionDelay = dataDelay+'ms';
  observer.observe(element) // run the observer 
});

// Function to unobserve element to prevent playing animation twice
function handleIntersection(entries, observer){
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        observer.unobserve(entry.target);
      }
    });
}